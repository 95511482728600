<i18n>
{
	"en": {
		"inputLabel": "Search",
		"noItemsText": "No items to show.",
		"noResultsText": "Your search didn't match any results."
	},
	"fi": {
		"inputLabel": "Haku",
		"noItemsText": "Ei näytettäviä kohteita.",
		"noResultsText": "Ei hakutuloksia."
	}
}
</i18n>

<template>
	<div>
		<template v-if="processedItems && processedItems.length">
			<v-sheet
				v-if="enableSearch"
				class="pt-6 pb-3"
			>
				<v-text-field
					v-model="searchQuery"
					:label="searchLabel || $i18n.t('inputLabel')"
					:hide-details="true"
					prepend-inner-icon="mdi-magnify"
					rounded
					clearable
					filled
				/>
			</v-sheet>
			<v-list v-if="filteredItems.length">
				<template v-for="(item, index) in filteredItems">
					<v-list-item
						:key="item[keySrc]"
						:href="(item[redirectUrlSrc]) ? item[redirectUrlSrc] : null"
						:target="(item[redirectUrlSrc]) ? '_blank' : null"
						v-on="enableClick ? { click: () => itemClick(item) } : {}"
					>
						<!-- Avatar -->
						<v-list-item-avatar v-if="avatarSrc && item[avatarSrc]">
							<template v-if="!$scopedSlots.avatar">
								<v-img
									alt=""
									:src="item[avatarSrc]"
								/>
							</template>
							<slot
								name="avatar"
								:item="item"
							/>
						</v-list-item-avatar>

						<!-- Icon -->
						<v-list-item-icon v-else-if="item[iconSrc]">
							<v-icon :color="(item[iconColorSrc]) ? item[iconColorSrc] : 'primary'">
								{{ item[iconSrc] }}
							</v-icon>
						</v-list-item-icon>

						<!-- Content  -->
						<v-list-item-content>
							<template v-if="!$scopedSlots.content">
								<v-list-item-subtitle
									v-if="item[subtitleSrc] || !!$scopedSlots.subtitle === true"
									:class="[
										multiline === true ? 'v-list-item__subtitle--multiline' : null
									]"
								>
									<span
										v-if="!!$scopedSlots.subtitle === false"
										v-html="item[subtitleSrc]"
									/>
									<slot
										name="subtitle"
										:item="item"
									/>
								</v-list-item-subtitle>
								<v-list-item-title
									v-if="item[titleSrc] || !!$scopedSlots.title === true"
									:class="[
										multiline === true ? 'v-list-item__title--multiline' : null
									]"
								>
									<span
										v-if="!!$scopedSlots.title === false"
										v-html="item[titleSrc]"
									/>
									<slot
										name="title"
										:item="item"
									/>
								</v-list-item-title>
								<v-list-item-subtitle
									v-if="item[summarySrc] || !!$scopedSlots.summary === true"
									:class="[
										multiline === true ? 'v-list-item__subtitle--multiline' : null
									]"
								>
									<span
										v-if="!!$scopedSlots.summary === false"
										v-html="item[summarySrc]"
									/>
									<slot
										name="summary"
										:item="item"
									/>
								</v-list-item-subtitle>
							</template>
							<slot
								name="content"
								:item="item"
							/>
						</v-list-item-content>

						<!-- Meta -->
						<v-list-item-action
							v-if="item[metaSrc] || !!$scopedSlots.meta === true"
						>
							<v-list-item-action-text
								v-if="!$scopedSlots.meta"
								v-html="item[metaSrc]"
							/>
							<slot
								name="meta"
								:item="item"
							/>
						</v-list-item-action>
					</v-list-item>
					<v-divider
						v-if="index != filteredItems.length - 1"
						:key="('divider-' + index)"
						:inset="(item.icon && item.icon.length > 0)"
					/>
				</template>
			</v-list>
			<p
				v-else
				class="pa-6"
			>
				{{ noResultsText || $i18n.t('noResultsText') }}
			</p>
		</template>
		<v-alert
			v-else
			type="info"
			class="mt-3"
		>
			{{ noItemsText || $i18n.t('noItemsText') }}
		</v-alert>
	</div>
</template>

<script>

export default {
	name: 'FilterableList',
	props: {
		// An array of items to render
		items: {
			type: Array,
			required: false,
			default: () => {
				return []
			},
		},

		// Maximum number of items to render
		maxItems: {
			type: Number,
			required: false,
			default: () => {
				return null
			},
		},

		// Enable multi-line texts?
		multiline: {
			type: Boolean,
			required: false,
			default () {
				return true
			},
		},

		// Item property to use for key in v-for loops
		keySrc: {
			type: String,
			required: false,
			default: () => {
				return 'id'
			},
		},

		// Item property to use for item avatar
		avatarSrc: {
			type: String,
			required: false,
			default: () => {
				return 'avatar'
			},
		},

		// Item property to use for item icon
		iconSrc: {
			type: String,
			required: false,
			default: () => {
				return 'icon'
			},
		},

		// Item property to use for item icon color
		iconColorSrc: {
			type: String,
			required: false,
			default: () => {
				return 'icon_color'
			},
		},

		// Item property to use for item title
		titleSrc: {
			type: String,
			required: false,
			default: () => {
				return 'title'
			},
		},

		// Item property to use for item subtitle
		subtitleSrc: {
			type: String,
			required: false,
			default: () => {
				return 'subtitle'
			},
		},

		// Item property to use for item summary
		summarySrc: {
			type: String,
			required: false,
			default: () => {
				return 'summary'
			},
		},

		// Item property to use for item meta
		// Use this for dates etc.
		metaSrc: {
			type: String,
			required: false,
			default: () => {
				return 'date'
			},
		},

		// Item property to use for item redirect url
		// If redirect url is defined, user will be redirected
		// when clicking list item.
		redirectUrlSrc: {
			type: String,
			required: false,
			default: () => {
				return 'redirect_url'
			},
		},

		// Allow user to click list items?
		enableClick: {
			type: Boolean,
			required: false,
			default: () => {
				return true
			},
		},

		// Enable search?
		enableSearch: {
			type: Boolean,
			required: false,
			default: () => {
				return true
			},
		},

		// Fields to search from
		searchFields: {
			type: Array,
			required: false,
			default: () => {
				return []
			},
		},

		// Function to filter a single item
		customFilter: {
			type: Function,
			required: false,
			default: (args = {}) => {
				return args.searchQueryRegex.test(args.item._search_index)
			},
		},

		// Label for search input
		searchLabel: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},

		// Text to show when there are no items to show
		noItemsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},

		// Text to show when there are no search results to show
		noResultsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},
	},
	data: () => ({
		searchQuery: '',
	}),
	computed: {
		// Get fields to search from, by default based on src props
		processedSearchFields () {
			if (this.enableSearch === false) return []
			if (this.searchFields.length) return this.searchFields

			return [
				this.titleSrc,
				this.subtitleSrc,
				this.summarySrc,
			]
		},

		// Return array of processed items, with search index added
		processedItems () {
			let items = JSON.parse(JSON.stringify(this.items))

			// If maximum number of items is defined, slice items array
			if (typeof this.maxItems === 'number' && this.maxItems >= 0) {
				items = items.slice(0, this.maxItems)
			}

			// If search is not enabled, no further actions are needed
			if (this.enableSearch === false) return items

			// Add search index to each item
			return items.map(item => {
				item._search_index = this.processedSearchFields.reduce((acc, field) => {
					if (typeof item[field] === 'undefined' || !item[field]) return acc

					if (typeof item[field] === 'object') {
						acc.push(JSON.stringify(item[field]))
					} else (
						acc.push(item[field])
					)
					return acc
				}, []).join(' ')

				return item
			})
		},

		// Return filtered items. We use this var to render list items.
		filteredItems () {
			if (!this.searchQuery || !this.searchQuery.length) return this.processedItems

			return this.processedItems.filter(item => this.customFilter({
				item: item,
				searchQuery: this.searchQuery,
				searchQueryRegex: new RegExp(this.searchQuery, 'i'),
			}))
		},
	},
	methods: {
		// Item click handler
		itemClick (item) {
			if (item[this.redirectUrlSrc]) return

			this.$emit('itemClick', item)
		},
	},
}
</script>
<style lang="scss">

.v-list-item__title--multiline,
.v-list-item__subtitle--multiline {
	overflow: visible;
	white-space: normal;
}

</style>
