import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#006495',
				secondary: '#5bc4f1',
				accent: '#eb5c66',
				error: '#eb5c66',
				info: '#006495',
				success: '#00b2b9',
				warning: '#fed060',
			},
			dark: {
				primary: '#006495',
				secondary: '#5bc4f1',
				accent: '#eb5c66',
				error: '#eb5c66',
				info: '#006495',
				success: '#00b2b9',
				warning: '#fed060',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
})
