<template>
	<div>
		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<v-container
			v-else
			class="container--narrow"
		>
			<v-card v-if="config.forms && config.forms.myAccount">
				<v-card-text>
					<SchemaToForm
						v-model="formData"
						:schema="config.forms.myAccount"
						:disabled="saveLoading"
						:debug="false"
						@valid="formValid = true"
						@invalid="formValid = false"
						@change="$store.commit('setConfirmNavigation', true)"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="primary"
						:loading="saveLoading"
						:disabled="saveLoading || !formValid"
						@click="save"
						text
					>
						{{ $i18n.t('general.save') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',
	data: () => ({
		ready: false,
		formData: {},
		saveLoading: false,
		formValid: false,
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		this.$api.Me.doRequest().then(() => {
			this.formData = JSON.parse(JSON.stringify(this.user))
			this.ready = true
		})
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.memberData) {
					this.formData = res.body.memberData
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
